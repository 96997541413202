import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Grid from '@material-ui/core/Grid';


const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: "100%",
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));

export default function SearchArea(props) {
    const classes = useStyles();
    const [cedula, setCedula] = React.useState("");
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
        props.searchAction(cedula);
          event.preventDefault();
          event.stopPropagation();
        }
      }
    return (
        <React.Fragment>
            <Grid container spacing={0}>
                <Grid item xs={12} sm={2}>
                Datos Contribuyente: (*)
                </Grid>
                <Grid item xs={12} sm={4}>
                <Paper component="form" className={classes.root}>
                <InputBase
                    className={classes.input}
                    placeholder="Ingrese su número de cédula"
                    inputProps={{ 'aria-label': 'Ingrese su número de cédula' }}
                    value={cedula}
                    onChange={(e) => {
                        setCedula(e.target.value);
                    }}
                    onKeyDown={handleKeyDown}
                />
                <IconButton className={classes.iconButton} aria-label="buscar" onClick={() => { props.searchAction(cedula); }}>
                    <SearchIcon />Buscar
                </IconButton>
            </Paper>
                </Grid>
                <Grid item xs={12} sm={4}>
                    
                </Grid>

            </Grid>




        </React.Fragment>
    );
}