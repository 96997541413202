import axios from 'axios';
class GeneralService {
  constructor() {
    this.api_url = "";
    this.geoserver_url = "";
    fetch(`${process.env.PUBLIC_URL}/config.json`).then((r) => r.json())
      .then((initOptions) => {
        this.api_url = initOptions.api_url;
        this.geoserver_url = initOptions.geoserver_url;
      });
  }
  testService=()=>{
    return axios.get(`http://www.intelectus-system.com/portal/servicio.php`);
  }
  getDebts = (id) => {
    return axios.get(`${this.api_url}/cajaunica/rest/caja/recaudacion/${id}`);
  }
  obtenerPredio = (id,sinat=0) => {
    return axios.post(`${this.api_url}/planificacionTerritorial/rest/irm/consultarInformePreliminar/${id}/${sinat}`);
  }
  busquedaCedula = (cedula) => {
    return axios.post(`${this.api_url}/planificacionTerritorial/rest/irm/buscarPredioPorCedula/${cedula}`);

  }
  busquedaCatastral = (catastro) => {
    return axios.post(`${this.api_url}/planificacionTerritorial/rest/irm/buscarPredioPorClaveCatastral/${catastro}`);
  }

  busquedaIcusCedula = (cedula) => {
    return axios.post(`${this.api_url}/planificacionTerritorial/rest/icus/buscarPredioPorCedula/${cedula}`);
  }
  busquedaIcusClaveCatastral = (catastro) => {
    return axios.post(`${this.api_url}/planificacionTerritorial/rest/icus/buscarPredioPorClaveCatastral/${catastro}`);
  }
  obtenerInformePreliminarIrm = (a, b) => {
    return axios.post(`${this.api_url}/planificacionTerritorial/rest/irm/consultarInformePreliminarPdf/${a}/${b}`);
  }
  crearInformeIrm = (a, b) => {
    return axios.post(`${this.api_url}/planificacionTerritorial/rest/irm/crearInforme/${a}/${b}`);
  }
  descargarInformeIrm = (codigo) => {
    return axios.post(`${this.api_url}/planificacionTerritorial/rest/irm/consultarInformePdf/${codigo}`);
  }
  obtenerInformePreliminarIcus = (predio, sinad, x, y) => {
    return axios.post(`${this.api_url}/planificacionTerritorial/rest/icus/consultarInformeGeneralPreliminarPdf/${predio}/${sinad}/${x}/${y}`);
  }
  crearInformeIcus = (predio, sinad, x, y) => {
    return axios.post(`${this.api_url}/planificacionTerritorial/rest/icus/crearInformeGeneral/${predio}/${sinad}/${x}/${y}`);

  }
  registrarNovedad = (preId,reqSinat,reqTipo,reqClaveCatastral,reqPeticion) => {
    let requirement={
      preId,reqSinat,reqTipo,reqClaveCatastral,reqPeticion
    };
    console.log("la peticion es",requirement);
    return axios.post(`${this.api_url}/planificacionTerritorial/rest/regulacion/addRequerimiento`,requirement);
  }
  descargarInformeIcus = (codigo) => {
    return axios.post(`${this.api_url}/planificacionTerritorial/rest/icus/consultarInformeGeneralPdf/${codigo}`);
  }
  descargarInformeIcusEspecifico = (codigo) => {
    return axios.post(`${this.api_url}/planificacionTerritorial/rest/icus/consultarInformePdf/${codigo}`);
  }
  generarInformeVistaPreviaIcus = (predio, sinad, x, y, icus) => {
    return axios.post(`${this.api_url}/planificacionTerritorial/rest/icus/consultarInformePreliminarPdf/${predio}/${sinad}/${x}/${y}/${icus}`);
  }
  generarInformeIcusProd = (predio, sinad, x, y, icus) => {
    return axios.post(`${this.api_url}/planificacionTerritorial/rest/icus/crearInforme/${predio}/${sinad}/${x}/${y}/${icus}`);
  }
  verInformeIcusProd = (icusId) => {
    return axios.post(`${this.api_url}/planificacionTerritorial/rest/icus/consultarInformePdf/${icusId}`);
  }

  ObtenerPredioIcus = (predio, sinat) => {
    return axios.post(`${this.api_url}/planificacionTerritorial/rest/icus/consultarInformeInicial/${predio}/${sinat}`);
  }
  ObtenerUbicacionIcus = (predio, sinad, x, y) => {
    return axios.post(`${this.api_url}/planificacionTerritorial/rest/icus/registrarUbicacion/${predio}/${sinad}/${x}/${y}`);
  }
  ObtenerTipologia = () => {
    return axios.post(`${this.api_url}/planificacionTerritorial/rest/icus/listarTipologia`);
  }
  ObtenerActividad = (actividad, tipologia) => {
    console.log("tipo", tipologia);
    return axios.post(`${this.api_url}/planificacionTerritorial/rest/icus/buscarActividad/${actividad}/${tipologia}`);
  }
  getActivityReport = (preId, sinat, x, y, activityCode) => {
    return axios.post(`${this.api_url}/planificacionTerritorial/rest/icus/consultarInformePreliminar/${preId}/${sinat}/${x}/${y}/${activityCode}`);
  }
  getReportList=(catastro)=>{
    return axios.post(`${this.api_url}/planificacionTerritorial/rest/irm/listaInformes/${catastro}`);
  }
  getReportListIcus=(catastro)=>{
    return axios.post(`${this.api_url}/planificacionTerritorial/rest/icus/listaInformes/${catastro}`);
  }
  getReportListIcusGeneral=(catastro)=>{
    return axios.post(`${this.api_url}/planificacionTerritorial/rest/icus/listaInformesGeneral/${catastro}`);
  }
  validateIrmSolicitude=(preId,sinat)=>{
    return axios.post(`${this.api_url}/planificacionTerritorial/rest/regulacion/listRequerimiento/${preId}/${sinat}`);
  }
  enviarArchivosLineaFabrica=(preId,reqSinat,reqClaveCatastral,adjuntos)=>{
    let toSend={
      preId,reqSinat,reqClaveCatastral,adjuntos
    };
    return axios.post(`${this.api_url}/planificacionTerritorial/rest/regulacion/solicitarIrm`,toSend);
  }
  addAnexoSolicitudIrm=(reqId,nombre,archivo)=>{
    let toSend={
      reqId,nombre,archivo
    };
    return axios.post(`${this.api_url}/planificacionTerritorial/rest/regulacion/addAnexoSolicitudIrm`,toSend);
  }
  getGeoServerUrl = () => {
    return this.geoserver_url;
  }
  getDebtsQuery=(id="1702520340133")=>{
    let config = {
      params:{id,tip_con:1}
    };
    //return axios.post(`http://172.23.7.9:8080/Virtual/logica/ConsultaImpuesto.php`,null,config);
    return axios.post(`https://impuestos.gadipmc.gob.ec/Virtual/logica/ConsultaImpuesto.php`,null,config);
  }

}
export default new GeneralService();

