import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import GeneralService from '../../apis/GeneralService';
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import {Link} from 'react-router-dom';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';

import GetAppIcon from '@material-ui/icons/GetApp'
import Button2 from '@material-ui/core/Button';

class BusquedaCedula extends Component {

    constructor(props) {
        super(props);
        this.state = {
            products: [],
            showInfo: false,
            expandedRows:null,
            reportsList:null,
            reportsListGeneral:null,
        };
    }

    componentDidMount() {
        switch(this.props.type){
            case 1:{
                GeneralService.busquedaIcusCedula(this.props.id).then(result=>{
                    this.setState({products:result.data.lista,showInfo:true});
                    this.props.stopLoader();
                },
                error=>{
                    NotificationManager.error('Error...Por favor intente nuevamente..', "", 5000, () => {});
                    this.props.stopLoader();
                });
                break;
            }
            case 2:{
                GeneralService.busquedaIcusClaveCatastral(this.props.id).then(result=>{
                    this.setState({products:result.data.lista,showInfo:true});
                    this.props.stopLoader();
                },
                error=>{
                    NotificationManager.error('Error..Por favor intente nuevamente..', "", 5000, () => {});
                    this.props.stopLoader();
                });
                
                break;
            }
            default: break;

        }

    }
    getReport = (rowData) => {
        //return  <Link to={`/icus/predio/${rowData.preId}/${rowData.sinat}`}>Solicitar ICUS</Link>;
        /**
         <!-- <Button2
        onClick={()=>{
            let obj={};
            obj[rowData.preId]=true;
            this.setState({expandedRows:obj,reportsList:null},()=>{
                GeneralService.getReportList(rowData.codigoCatastral).then(result=>{
                    if(typeof result.data.informes!=="undefined"){
                        this.setState({reportsList:result.data.informes});
                    }
                });
           }); 
        }} 
            variant="outlined"
            color="default"
            startIcon={<LibraryBooksIcon />}
            style={{margin:"5px"}}
        >
            Mostrar Informes
        </Button2>-->
        */
        return <div>
       <Button2
        onClick={()=>{
            let obj={};
            obj[rowData.preId]=true;
            this.setState({expandedRows:obj,reportsList:null},()=>{
                GeneralService.getReportListIcus(rowData.codigoCatastral).then(result=>{
                    if(typeof result.data.informes!=="undefined"){
                        this.setState({reportsList:result.data.informes});
                    }
                });
                GeneralService.getReportListIcusGeneral(rowData.codigoCatastral).then(result=>{
                    if(typeof result.data.informes!=="undefined"){
                        this.setState({reportsListGeneral:result.data.informes});
                    }
                });

           }); 
        }} 
            variant="outlined"
            color="default"
            startIcon={<LibraryBooksIcon />}
            style={{margin:"5px"}}
        >
            Mostrar Informes
        </Button2>
        <Link to={`/icus/predio/${rowData.preId}/${rowData.sinat}`}>
        <Button2
        onClick={()=>{
            //this.onClick('displayBasic',()=>{this.props.searchAction(rowData.preId,"predio",rowData.sinat);});
            
        }}
            variant="outlined"
            color="default"
            startIcon={<CloudUploadIcon />}
            style={{margin:"5px"}}
        >
            Solicitar Icus
        </Button2>
        </Link>
        </div>
    }
    getCategory = (rowData) => {
        return  <div>{parseInt(rowData.sinat)===0?"URBANO":"RURAL"}</div>;
    }
    getPdfRow=(rowData)=>{
        return <Button2
        onClick={() => {
            this.getPdfReport(rowData.numeroInforme);
           }}
            variant="outlined"
            color="default"
            startIcon={<GetAppIcon />}
            style={{margin:"5px"}}
        >
            Descargar Informe
        </Button2>;               
    }
    getPdfRowGeneral=(rowData)=>{
        return <Button2
        onClick={() => {
            this.getPdfReportGeneral(rowData.numeroInforme);
           }}
            variant="outlined"
            color="default"
            startIcon={<GetAppIcon />}
            style={{margin:"5px"}}
        >
            Descargar Informe
        </Button2>;               
    }
    rowExpansionTemplate=(data)=>{
        return (
            <div className="orders-subtable">
                <h5>Detalle de Informe Espec&iacute;fico </h5>
                <DataTable value={this.state.reportsList}
                emptyMessage="No se encuentran registros">
                    <Column field="numeroInforme" header="Informe #"></Column>
                    <Column field="nombreRazonSocial" header="Razón Social"></Column>
                    <Column field="parroquia" header="Parroquia"></Column>
                    <Column field="barrio" header="Barrio"></Column>
                    <Column header="Obtener Informe" body={this.getPdfRow}></Column>
                </DataTable>

                <h5>Detalle de Informe General </h5>
                <DataTable value={this.state.reportsListGeneral}
                emptyMessage="No se encuentran registros">
                    <Column field="numeroInforme" header="Informe #"></Column>
                    <Column field="nombreRazonSocial" header="Razón Social"></Column>
                    <Column field="parroquia" header="Parroquia"></Column>
                    <Column field="barrio" header="Barrio"></Column>
                    <Column header="Obtener Informe" body={this.getPdfRowGeneral}></Column>
                </DataTable>
            </div>
        );
    }
    downloadPdf=(pdf,nombre="Vista Previa")=>{
        let linkSource = 'data:application/octet-stream;base64,'+pdf;
        const downloadLink = document.createElement("a");
        const fileName = nombre+".pdf";
    
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }
    getPdfReport=(numInforme)=>{
        GeneralService.descargarInformeIcusEspecifico(numInforme).then(resultado=>{
            let pdfData=resultado.data;
        this.downloadPdf(pdfData.icus.pdf.toString(),"Informe Específico - Icus");
        });
    }

    getPdfReportGeneral=(numInforme)=>{
        GeneralService.descargarInformeIcus(numInforme).then(resultado=>{
            let pdfData=resultado.data;
        this.downloadPdf(pdfData.icus.pdf.toString(),"Informe General - Icus");
        });
    }


    render() {
        return (
            <div>
                <br />
                {this.state.showInfo&&
                <div className="card">
                    <DataTable value={this.state.products}
                    emptyMessage="No se encuentran registros"
                    selectionMode="single" dataKey="preId"
                    expandedRows={this.state.expandedRows} 
                    onRowExpand={(event)=>{
                        let obj={};
                        obj[event.data.preId]=true;
                        this.setState({expandedRows:obj,reportsList:null,reportsListGeneral:null},()=>{


                            GeneralService.getReportListIcus(event.data.codigoCatastral).then(result=>{
                                if(typeof result.data.informes!=="undefined"){
                                    this.setState({reportsList:result.data.informes});
                                }
                            });

                            GeneralService.getReportListIcusGeneral(event.data.codigoCatastral).then(result=>{
                                console.log("el resultado es",result.data);
                                if(typeof result.data.informes!=="undefined"){
                                    this.setState({reportsListGeneral:result.data.informes});
                                }
                            });


                       }); 
                    }}
                    onRowToggle={(e) => {
                        console.log(e.data);
                    }}
                    onRowCollapse={()=>{
                        this.setState({expandedRows:{}});
                    }}
                    rowExpansionTemplate={this.rowExpansionTemplate}
                    >
                        <Column expander header="" style={{width:"5%"}}/>
                        <Column header="Categoría" body={this.getCategory}></Column>
                        <Column field="identificacionPropietario" header="Cedula"></Column>
                        <Column field="codigoCatastral" header="Código Catastral" style={{width:"30%"}}></Column>
                        <Column field="nombrePropietario" header="Propietario"></Column>
                        <Column header="Informe" body={this.getReport}></Column>
                    </DataTable>
                </div>
            }
            </div>
        );
    }
}
export default BusquedaCedula;