import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import GeneralService from '../../apis/GeneralService';
import { NotificationManager } from 'react-notifications';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import Button2 from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Link } from 'react-router-dom';
import '../../../assets/css/modal.css';
import '../../../assets/css/DataTable.css';
import 'react-notifications/lib/notifications.css';
import banner from "../../../assets/images/banner.jpg";
import FileBase64 from 'react-file-base64';
import Tooltip from '@material-ui/core/Tooltip';

class BusquedaCedula extends Component {

    constructor(props) {
        super(props);
        this.state = {
            products: [],
            showInfo: false,
            displayBasic: false,
            displayBasic2: false,
            displayModal: false,
            displayMaximizable: false,
            displayPosition: false,
            displayResponsive: false,
            position: 'center',
            toExecute: null,
            expandedRows: null,
            reportsList: null,
            file: null,
            pendingRequests: null,
            reqId: null,
            requirementsList: null
        };
        this.onClick = this.onClick.bind(this);
        this.onHide = this.onHide.bind(this);
    }
    formatBase64 = (strObj) => {
        strObj["base64"] = strObj["base64"].replace("data:application/pdf;base64,", "");
        return strObj;
    }
    getFile = (file) => {
        file = this.formatBase64(file);
        file["name"] = "DERECHO DE INSPECCION";
        this.setState({ file });
    }
    onClick(name, toExecute) {
        let state = {
            [`${name}`]: true,
            toExecute
        };
        this.setState(state);
    }

    onHide(name) {
        this.setState({
            [`${name}`]: false
        });
    }
    sendInspectionFile = (name) => {
        if (this.state.reqId !== null && this.state.file !== null) {


            if (this.state.file.type === "application/pdf") {
                GeneralService.addAnexoSolicitudIrm(this.state.reqId, "DERECHO INSPECCIÓN", this.state.file.base64)
                    .then(rsp => {
                        NotificationManager.success('Archivo subido correctamente..', "", 5000, () => { });
                        this.onHide(name);
                    }, error => {
                        NotificationManager.error('Error..Por favor intente nuevamente..', "", 5000, () => { });
                        this.onHide(name);
                    });
            }
            else {
                NotificationManager.error('Solo se admiten archivos pdf', "", 5000, () => { });
            }
        }


    }
    renderFooter(name) {
        return (
            <div>
                <Button label="Aceptar" icon="pi pi-check" onClick={() => {
                    if (typeof this.state.toExecute === "function") this.state.toExecute();
                    this.onHide(name);
                }} autoFocus />
            </div>
        );
    }
    renderFooter2(name) {
        return (
            <div>
                <Button label="Cancelar" icon="pi pi-times" onClick={() => this.onHide(name)} className="p-button-text" />
                <Button label="Enviar" icon="pi pi-check" onClick={() => {
                    this.sendInspectionFile(name);
                }} autoFocus
                />
            </div>
        );
    }
    componentDidMount() {
        switch (this.props.type) {
            case 1: {
                GeneralService.busquedaCedula(this.props.id).then(result => {
                    this.setState({ products: result.data.lista, showInfo: true });
                    this.props.stopLoader();
                },
                    error => {
                        NotificationManager.error('Error...Por favor intente nuevamente..', "", 5000, () => { });
                        this.props.stopLoader();
                    });
                break;
            }
            case 2: {
                GeneralService.busquedaCatastral(this.props.id).then(result => {
                    this.setState({ products: result.data.lista, showInfo: true });
                    this.props.stopLoader();
                },
                    error => {
                        NotificationManager.error('Error..Por favor intente nuevamente..', "", 5000, () => { });
                        this.props.stopLoader();
                    });
                break;
            }
            default: break;

        }

    }
    downloadPdf = (pdf, nombre = "Informe") => {
        let linkSource = 'data:application/octet-stream;base64,' + pdf;
        const downloadLink = document.createElement("a");
        const fileName = nombre + ".pdf";

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }
    getCatastro = (rowData) => {
        if (parseInt(rowData.sinat) === 0) return "URBANO";
        return "RURAL";
    }
    getPdfReport = (numInforme) => {
        GeneralService.descargarInformeIrm(numInforme).then(resultado => {
            let pdfData = resultado.data;
            this.downloadPdf(pdfData.irm.pdf.toString(), "Informe Específico - Regulación Municipal");
        });
    }
    getPdfRow = (rowData) => {
        return <Button2
            onClick={() => {
                this.getPdfReport(rowData.numeroInforme);
            }}
            variant="outlined"
            color="default"
            startIcon={<GetAppIcon />}
            style={{ margin: "5px" }}
        >
            Descargar Informe
        </Button2>;
    }
    getPdfRow = (rowData) => {
        return <Button2
            onClick={() => {
                this.getPdfReport(rowData.numeroInforme);
            }}
            variant="outlined"
            color="default"
            startIcon={<GetAppIcon />}
            style={{ margin: "5px" }}
        >
            Descargar Informe
        </Button2>;
    }
    getRequirementState = (rowData) => {
        let valueResult = "";
        switch (parseInt(rowData.reqEstado)) {
            case 1: {
                valueResult = "Pendiente";
                break;
            }
            case 3: {
                valueResult = "Pendiente de Inspección";
                break;
            }
            default: break;
        }
        return valueResult;
    }
    getRequirementType = (rowData) => {
        let valueResult = "";
        switch (parseInt(rowData.reqTipo)) {
            case 10: {
                valueResult = "Solicitud de Regulación Municipal";
                break;
            }
            case 1: {
                valueResult = "Actualización de Datos Catastrales";
                break;
            }
            case 2: {
                valueResult = "Actualización de Datos de Planificación Territorial";
                break;
            }
            default: break;
        }
        return valueResult;
    }
    getInspectionButton = (rowData) => {
        if (parseInt(rowData.reqEstado) === 3) 
            return <Button2
                onClick={() => {
                    this.setState({ reqId: null }, () => {
                        this.setState({ reqId: rowData.reqId, displayBasic2:true});
                });
            }}
                variant="outlined"
                color="default"
                startIcon={<LibraryBooksIcon />}
                style={{ margin: "5px" }}
            >
                Subir Archivo
            </Button2>
        else return "";
    }
    rowExpansionTemplate = (data) => {
        return (
            <div className="orders-subtable">
                <h5>Detalle de Informes </h5>
                <DataTable value={this.state.reportsList} emptyMessage="No se encuentran registros">
                    <Column field="numeroInforme" header="Informe #"></Column>
                    <Column field="nombreRazonSocial" header="Razón Social"></Column>
                    <Column field="parroquia" header="Parroquia"></Column>
                    <Column field="barrio" header="Barrio"></Column>
                    <Column header="Obtener Informe" body={this.getPdfRow}></Column>
                </DataTable>
                <h5>Requerimientos </h5>
                <DataTable value={this.state.requirementsList} emptyMessage="No se encuentran registros">
                    <Column header="Estado" body={this.getRequirementState}></Column>
                    <Column field="reqId" header="Número de Trámite"></Column>
                    <Column header="Tipo" body={this.getRequirementType}></Column>
                    <Column header="Acción" body={this.getInspectionButton}></Column>
                </DataTable>
            </div>
        );
    }
    getDescription = (id) => {
        let desc = "";
        switch (id) {
            case 1: {
                desc = "Catastro";
                break;
            }
            case 2: {
                desc = "Planificación";
                break;
            }
            case 10: {
                desc = "Solicitud Regulación Municipal";
                break;
            }
            default: break;
        }
        return desc;
    }
    getReport = (rowData) => {
        return <div>
            <Tooltip title="Ver trámites pendientes e informes">
                <Button2
                    onClick={() => {
                        let obj = {};
                        obj[rowData.preId] = true;
                        this.setState({ expandedRows: obj, reportsList: null, requirementsList: null }, () => {
                            GeneralService.getReportList(rowData.codigoCatastral).then(result => {
                                if (typeof result.data.informes !== "undefined") {
                                    this.setState({ reportsList: result.data.informes });
                                }
                            });
                            GeneralService.validateIrmSolicitude(rowData.preId, rowData.sinat).then(
                                result => {
                                    if (typeof result.data[0] !== "undefined") {
                                        let requirementsList = result.data.filter(rowData => (parseInt(rowData.reqEstado) === 1 || parseInt(rowData.reqEstado) === 3) && parseInt(rowData.reqTipo) !== 1);
                                        this.setState({ requirementsList });
                                    }
                                });
                        });
                    }}
                    variant="outlined"
                    color="default"
                    startIcon={<LibraryBooksIcon />}
                    style={{ margin: "5px" }}
                >
                    Mostrar Informes
                </Button2>
            </Tooltip>
            <Button2
                onClick={() => {
                    //this.onClick('displayBasic',()=>{this.props.searchAction(rowData.preId,"predio",rowData.sinat);});
                    GeneralService.validateIrmSolicitude(rowData.preId, rowData.sinat).then(
                        result => {
                            if (typeof result.data[0] !== "undefined") {
                                let reqEstado = 0;
                                let derechoInspeccion = false;
                                let pendingRequests = [];
                                let reqId = null;
                                let catastroSalto = false;

                                let recorsToShow = result.data.filter(rowData => (parseInt(rowData.reqEstado) === 1 || parseInt(rowData.reqEstado) === 3) && parseInt(rowData.reqTipo) !== 1);
                                recorsToShow.forEach(rowData => {
                                    if (typeof rowData.reqEstado !== "undefined") {
                                        if (parseInt(rowData.reqEstado) === 1 && parseInt(rowData.reqTipo) !== 1) reqEstado = 1;
                                        if (parseInt(rowData.reqEstado) === 3) {
                                            derechoInspeccion = true;
                                            reqId = rowData.reqId;
                                        }
                                        if (parseInt(rowData.reqEstado) === 1 && parseInt(rowData.reqTipo) === 1) catastroSalto = true;
                                        let dsc = this.getDescription(parseInt(rowData.reqTipo));
                                        if (dsc.length > 0) {
                                            pendingRequests.push({ reqId: rowData.reqId, description: dsc });
                                        }
                                    }

                                });
                                if ((reqEstado === 0 && !derechoInspeccion)) {
                                    //this.onClick('displayBasic2'); ///remover esta linea
                                    this.props.stopLoader();
                                    this.props.searchAction(rowData.preId, "predio", rowData.sinat);

                                }
                                else if (catastroSalto && !derechoInspeccion && reqEstado === 0) {
                                    //this.onClick('displayBasic2'); ///remover esta linea
                                    this.props.stopLoader();
                                    this.props.searchAction(rowData.preId, "predio", rowData.sinat);

                                }
                                else if (derechoInspeccion) {
                                    this.setState({
                                        reqId
                                    }, () => {
                                        this.onClick('displayBasic2');
                                    });
                                }
                                else {
                                    this.setState({ pendingRequests }, () => {
                                        this.onClick('displayBasic');
                                    });
                                }
                            }
                            else {
                                this.props.searchAction(rowData.preId, "predio", rowData.sinat);
                            }

                        }
                        , error => {

                        }
                    );

                }}
                variant="outlined"
                color="default"
                startIcon={<CloudUploadIcon />}
                style={{ margin: "5px" }}
            >
                Solicitar informe IRM
            </Button2>
        </div>;
    }
    render() {
        return (
            <div>
                <br />
                <Dialog header="Advertencia" visible={this.state.displayBasic} style={{ width: '50vw' }} footer={this.renderFooter('displayBasic')} onHide={() => this.onHide('displayBasic')}>
                    <p>Actualmente ya existe una solicitud Pendiente</p>
                    <DataTable value={this.state.pendingRequests} emptyMessage="No se encuentran registros">
                        <Column field="reqId" header="Id"></Column>
                        <Column field="description" header="Descripción"></Column>
                    </DataTable>
                </Dialog>
                <Dialog header="DERECHO DE INSPECCIÓN (Solo se permiten archivos pdf)" visible={this.state.displayBasic2} style={{ width: '50vw' }} footer={this.renderFooter2('displayBasic2')} onHide={() => this.onHide('displayBasic2')}>
                    <p>Los documentos a cargar deben estar en formato .PDF, o no se podr&aacute; continuar con la solicitud</p>
                    <p>Por favor suba el archivo:<FileBase64 multiple={false} onDone={this.getFile} /></p>
                </Dialog>
                {this.state.showInfo &&
                    <div className="card">
                        <div className="datatable-rowexpansion-demo">
                            <DataTable value={this.state.products}
                                emptyMessage="No se encuentran registros"
                                dataKey="preId"
                                expandedRows={this.state.expandedRows}
                                onRowExpand={(event) => {
                                    let obj = {};
                                    obj[event.data.preId] = true;
                                    this.setState({ expandedRows: obj, reportsList: null }, () => {
                                        GeneralService.getReportList(event.data.codigoCatastral).then(result => {
                                            if (typeof result.data.informes !== "undefined") {
                                                this.setState({ reportsList: result.data.informes });
                                            }
                                        });

                                        GeneralService.validateIrmSolicitude(event.data.preId, event.data.sinat).then(
                                            result => {
                                                if (typeof result.data[0] !== "undefined") {
                                                    let requirementsList = result.data.filter(rowData => (parseInt(rowData.reqEstado) === 1 || parseInt(rowData.reqEstado) === 3) && parseInt(rowData.reqTipo) !== 1);
                                                    this.setState({ requirementsList });

                                                }
                                            });
                                    });
                                }}
                                onRowToggle={(e) => {
                                    console.log(e.data);
                                }}
                                onRowCollapse={() => {
                                    this.setState({ expandedRows: {} });
                                }}
                                rowExpansionTemplate={this.rowExpansionTemplate}
                            >
                                <Column expander header="" style={{ width: "5%" }} />
                                <Column field="identificacionPropietario" header="Cedula"></Column>
                                <Column field="codigoCatastral" header="Código Catastral" style={{ width: "30%" }}></Column>
                                <Column field="nombrePropietario" header="Propietario"></Column>
                                <Column header="Catastro" body={this.getCatastro}></Column>
                                <Column header="Informe" body={this.getReport}></Column>
                            </DataTable>
                            <div id="footer"><img src={banner} width="100%" /></div>
                        </div>
                    </div>
                }

            </div>
        );
    }
}
export default BusquedaCedula;