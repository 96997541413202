import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { Dropdown } from 'primereact/dropdown';
import Grid from '@material-ui/core/Grid';


const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: "100%",
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));
const options = [
    { name: 'Cédula', code: 'cedula' },
    { name: 'Clave Catastral', code: 'claveCatastral' }
];
export default function SearchPredio(props) {
    const classes = useStyles();
    const [predio, setPredio] = React.useState("");
    const [selectedOption, setOption] = React.useState({name: "Clave Catastral", code: "claveCatastral"});

    const optionChange = (e) => {
        setOption(e.value);
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          props.searchAction(predio, (selectedOption !== null) ? selectedOption.code : "");
          event.preventDefault();
          event.stopPropagation();
        }
      }
    return (
        <React.Fragment>
            <Grid container spacing={0}>
                <Grid item xs={12} sm={2}>Datos del Usuario: (*) </Grid>
                <Grid item xs={12} sm={4}>
                    <Paper component="form" className={classes.root}>
                        <InputBase
                            className={classes.input}
                            placeholder="Ingrese número"
                            inputProps={{ 'aria-label': 'Ingrese número' }}
                            value={predio}
                            onChange={(e) => {
                                setPredio(e.target.value);
                            }}
                            onKeyDown={handleKeyDown}
                        />
                        <Dropdown value={selectedOption} options={options} onChange={optionChange} optionLabel="name" placeholder="Buscar por:" />
                        <IconButton className={classes.iconButton} aria-label="buscar" onClick={() => { props.searchAction(predio, (selectedOption !== null) ? selectedOption.code : ""); }}>
                            <SearchIcon />
                        </IconButton>

                    </Paper>
                </Grid>
                <Grid item xs={12} sm={4}></Grid>
            </Grid>
        </React.Fragment>
    );
}