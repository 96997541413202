import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import GeneralService from '../../apis/GeneralService';
class BusquedaCatastral extends Component {

    constructor(props) {
        super(props);
        this.state = {
            products: [],
            showInfo: false
        };
    }

    componentDidMount() {
        GeneralService.busquedaCatastral(this.props.id).then(result=>{
            this.setState({products:result.data.lista,showInfo:true});
        },
        error=>{

        });
    }

    render() {
        return (
            <div>
                <br />
                {this.state.showInfo&&
                <div className="card">
                    <DataTable value={this.state.products}
                    emptyMessage="No se encuentran registros"
                    onSelectionChange={(e) => {
                        this.props.searchAction(e.value.preId,"predio");
                    }}
                    selectionMode="single" dataKey="preId"
                    >
                        <Column field="identificacionPropietario" header="Cedula"></Column>
                        <Column field="codigoCatastral" header="Código Catastral"></Column>
                        <Column field="nomrePropietario" header="Propietario"></Column>
                        <Column field="preId" header="Predio"></Column>
                    </DataTable>
                </div>
            }
            </div>
        );
    }
}
export default BusquedaCatastral;