import React, { Component } from 'react';
import { NotificationManager } from 'react-notifications';
import GeneralService from '../../apis/GeneralService';
import { Panel } from 'primereact/panel';
import Grid from '@material-ui/core/Grid';

import { Button } from 'primereact/button';
import 'react-notifications/lib/notifications.css';

class Predio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showInfo: false,
            showError: false,
            error: ""
        }
    }
    componentDidMount() {
        GeneralService.obtenerPredio(this.props.id).then(result => {
            this.setState({ userData: result.data }, () => {
                if (parseInt(result.data.status.codigo) === 0) {
                    NotificationManager.error('Error... ' + result.data.status.msg, "", 5000, () => { });
                }
                else
                    this.setState({ showInfo: true });
            }
            );
        }
            ,
            error => {
                NotificationManager.error('Error... Por favor intente nuevamente..', "", 5000, () => { });
            });
    }
    render() {
        return (
            <React.Fragment>
                {this.state.showInfo &&
                    <div>
                        <Panel header="INFORME DE REGULACIÓN CANTONAL" style={{ maxWidth: "100%" }}>

                        </Panel>
                        <Grid container spacing={0}>
                            <Grid item xs={12} sm={6}>
                                <Panel header="INFORMACIÓN PREDIAL EN UNIPROPIEDAD" style={{ textAlign: "center", maxWidth: "100%", display: "block", marginLeft: "auto", marginRight: "auto" }}>
                                    <table border="1" width="100%" style={{ textAlign: "left" }}>
                                        <tr>
                                            <td colSpan={2}><b>DATOS DEL TITULAR DE DOMINIO</b></td>
                                        </tr>
                                        <tr>
                                            <td width="50%">C.C/R.U.C</td>
                                            <td>{this.state.userData.informacionCatastral.titularDominio.cedulaRuc}</td>
                                        </tr>
                                        <tr>
                                            <td>Nombre o Raz&oacute;n Social</td>
                                            <td>{this.state.userData.informacionCatastral.titularDominio.nombreRazonSocial}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}><b>DATOS DEL PREDIO</b></td>
                                        </tr>
                                        <tr>
                                            <td>Clave Catastral</td>
                                            <td>{this.state.userData.informacionCatastral.predio.claveCatastral}</td>
                                        </tr>
                                        <tr>
                                            <td>Clave Catastral Anterior</td>
                                            <td>{this.state.userData.informacionCatastral.predio.claveCatastralAnterior}</td>
                                        </tr>
                                        <tr>
                                            <td>En derechos y acciones</td>
                                            <td>{(this.state.userData.informacionCatastral.predio.derechosAcciones) ? "SI" : "NO"}</td>
                                        </tr>
                                        <tr>
                                            <td>&Aacute;rea de construcci&oacute;n</td>
                                            <td>{this.state.userData.informacionCatastral.predio.areaConstruccion} m2</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}><b>DATOS DEL LOTE</b></td>
                                        </tr>
                                        <tr>
                                            <td>&Aacute;rea seg&uacute;n escritura:</td>
                                            <td>{this.state.userData.informacionCatastral.lote.areaEscritura}</td>
                                        </tr>
                                        <tr>
                                            <td>&Aacute;rea seg&uacute;n gr&aacute;fica:</td>
                                            <td>{this.state.userData.informacionCatastral.lote.areaGrafica}</td>
                                        </tr>
                                        <tr>
                                            <td>&Aacute;rea gr&aacute;fica</td>
                                            <td>{this.state.userData.informacionCatastral.lote.areaGrafica}</td>
                                        </tr>
                                        <tr>
                                            <td>Frente Principal:</td>
                                            <td>{this.state.userData.informacionCatastral.lote.frenteTotal}</td>
                                        </tr>
                                        <tr>
                                            <td>Parroquia:</td>
                                            <td>()</td>
                                        </tr>
                                        <tr>
                                            <td>Barrio/Sector:</td>
                                            <td>-----</td>
                                        </tr>
                                    </table>
                                </Panel>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Panel header={"IMPLANTACIÓN GRÁFICA DEL LOTE"} style={{ maxWidth: "100%", display: "block", marginLeft: "auto !important", marginRight: "auto !important" }}>

                                    <img src={"data:image/png;base64," + this.state.userData.informacionCatastral.ubicacionLote.grafico} width="50%" style={{ marginLeft: "auto", marginRight: "auto", display: "block" }} />

                                </Panel>
                            </Grid>
                        </Grid>
                        <Panel header="REGULACIONES" style={{ maxWidth: "100%" }}>
                            <b>ZONIFICACI&Oacute;N</b>
                            <Grid container spacing={0}>
                                <Grid item xs={8}>
                                    {this.state.userData.regulaciones.map(regulacion =>
                                        <div>
                                            <b>ZONA</b>
                                            <br />
                                            Zona: {regulacion.zona}
                                            <br />
                                            Lote m&iacute;nimo: {regulacion.loteMinimo}
                                            <br />
                                            Frente m&iacute;nimo: {regulacion.frenteMinimo}
                                            <br />
                                            COS PB: {regulacion.cosPb} %
                                            <br />
                                            COS TOTAL: {regulacion.cosTotal}%
                                            <br />
                                            Forma de ocupaci&oacute;n de suelo: {regulacion.formaOcupacion.nombre}
                                            <br />
                                            <b>CLASIFICACI&Oacute;N DE SUELO:</b> ({regulacion.clasificacionSuelo.codigo}) {regulacion.clasificacionSuelo.nombre}
                                            <br />
                                            <b>USO DE SUELO DE SUELO:</b> ({regulacion.usoSuelo.codigo}) {regulacion.usoSuelo.nombre}
                                        </div>
                                    )}
                                </Grid>
                                <Grid item xs={2}>
                                    <b>PISOS</b>
                                    {this.state.userData.regulaciones.map(regulacion =>
                                        <div>
                                            <br />
                                            Altura: {regulacion.alturaPisos}m
                                            <br />
                                            N&uacute;mero de pisos: {regulacion.numeroPisos}
                                        </div>
                                    )}
                                </Grid>
                                <Grid item xs={2}>
                                    <b>RETIROS</b>
                                    {this.state.userData.regulaciones.map(regulacion =>
                                        <div>
                                            <br />
                                            Frontal: {regulacion.retiroFrontal} m
                                            <br />
                                            Lateral 1: {regulacion.retiroLateral1} m
                                            <br />
                                            Lateral 2: {regulacion.retiroLateral2} m
                                            <br />
                                            Posterior: {regulacion.retiroPosterior} m
                                            <br />
                                            Entre bloques: {regulacion.distanciaEntreBloques} m
                                        </div>
                                    )}
                                </Grid>
                            </Grid>
                        </Panel>
                        <Panel header="COBERTURA DE SERVICIOS BÁSICOS" style={{ maxWidth: "100%" }}>
                            <Grid container spacing={0}>
                                <Grid item xs={6}>
                                    <b>ABASTECIMIENTO DE AGUA:</b> {this.state.userData.servicios.agua}
                                    <br />
                                    <b>ENERG&Iacute;A EL&Eacute;CTRICA:</b> {this.state.userData.servicios.electricidad}
                                </Grid>
                                <Grid item xs={6}>
                                    <b>ALCANTARILLADO:</b> {this.state.userData.servicios.alcantarillado}
                                    <br />
                                    <b>OTROS:</b> {this.state.userData.servicios.otros}
                                </Grid>
                            </Grid>
                        </Panel>
                    </div>
                }
                {this.state.showError &&
                    <div style={{ color: "red" }}>
                        <br />
                        {this.state.error}
                    </div>}
            </React.Fragment>
        );
    }
}
export default Predio;