import React, { Component } from 'react';
import { NotificationManager } from 'react-notifications';
import GeneralService from '../../apis/GeneralService';
import { Panel } from 'primereact/panel';
import Grid from '@material-ui/core/Grid';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import '../../../assets/css/Dialog.css';
import 'react-notifications/lib/notifications.css';
import FileBase64 from 'react-file-base64';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterTiltShiftSharp } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';

class Predio extends Component {
    constructor(props) {        
        super(props);
        this.state = {
            sinatValue: props.sinat,
            showInfo: false,
            showError: false,
            showNext: true,
            error: "",
            novedades: "",
            displayResponsive: false,
            displayBasic: false,
            finalmsj: false,
            displayBasic3: false,
            displayBasic4: false,
            displayBasic5: false,
            displayBasic6: false,
            novedadRegistrada: false,
            toExecute: null,
            sentSolicitude: false,
            files: [],
            file1: null,
            file3: null,
            file4: null,
            file5: null,
            file6: null,
            novedadesmsj: false,
        };
        this.onClick = this.onClick.bind(this);
        this.onHide = this.onHide.bind(this);
        this.onClickCustomize = this.onClick.bind(this);
    }
    formatBase64 = (strObj) => {
        strObj["base64"] = strObj["base64"].replace("data:application/pdf;base64,", "");
        return strObj;
    }
    getFiles(files) {
        let modifiedFiles = [];
        files.forEach(record => {
            let localStr = record;
            localStr["base64"] = localStr["base64"].replace("data:application/pdf;base64,", "");
            modifiedFiles.push(localStr);
        });
        this.setState({ files: modifiedFiles });
    }
    getFile1 = (file) => {
        file = this.formatBase64(file);
        file["name"] = "FORMULARIO DE TRAMITES DE ADMINSTRACION DE TERRITORIO";
        this.setState({ file1: file });
    }
    getFile3 = (file) => {
        file = this.formatBase64(file);
        file["name"] = "COPIA DE LA ESCRITURA DEBIDAMENTE REGISTRADA";
        this.setState({ file3: file });
    }
    getFile4 = (file) => {
        file = this.formatBase64(file);
        file["name"] = "COPIA DE CERTIFICADO DE GRAVAMEN ACTUALIZADO";
        this.setState({ file4: file });
    }
    getFile5 = (file) => {
        file = this.formatBase64(file);
        file["name"] = "PAGO DEL IMPUESTO PREDIAL ACTUALIZADO";
        this.setState({ file5: file });
    }
    getFile6 = (file) => {
        file = this.formatBase64(file);
        file["name"] = "CERTIFICADO DE NO ADEUDAR AL MUNICIPIO";
        this.setState({ file6: file });
    }

    onClickCustomize(name, toExecute) {
        let state = {
            [`${name}`]: true,
            toExecute
        };
        this.setState(state);
    }
    onClick(name, position) {
        this.setState({ novedades: "" });
        let state = {
            [`${name}`]: true
        };

        if (position) {
            state = {
                ...state,
                position
            }
        }

        this.setState(state);
    }

    onHide(name) {
        this.setState({
            [`${name}`]: false
        });
    }

    alertRegister = () => {
        this.setState({ novedadRegistrada: true });
        GeneralService.registrarNovedad(
            this.state.userData.informacionCatastral.predio.catPredioId,
            this.props.sinat,
            this.state.showNext ? 1 : 2,
            this.state.userData.informacionCatastral.predio.claveCatastral,
            this.state.novedades
        ).then(response => {
            NotificationManager.success('Novedad registrada', "", 5000, () => { });
            if (this.state.showNext) this.onClick('displayBasic', () => { });
            else this.setState({ novedadesmsj: true });
            //this.setState({ novedadesmsj:true});
            //this.setState({ sentSolicitude: true });
        },
            error => {
                NotificationManager.error('Error... Por favor intente nuevamente..', "", 5000, () => { });
                if (this.state.showNext) this.onClick('displayBasic', () => { });
                else this.setState({ novedadesmsj: true });
            });
    }

    renderFooter(name) {
        return (
            <div>
                <Button label="Cancelar" icon="pi pi-times" onClick={() => this.onHide(name)} className="p-button-text" />
                <Button label="Registrar Novedad" icon="pi pi-check" onClick={() => {
                    this.alertRegister();
                    this.onHide(name);
                }} autoFocus
                />
            </div>
        );
    }

    renderNovedadesFooter(name) {
        return (
            <div>
                <Button label="Aceptar" icon="pi pi-times" onClick={() => this.onHide(name)} className="p-button-text" />
            </div>
        );
    }
    renderFooter2(name) {
        return (
            <div>
                <Button label="Cerrar" icon="pi pi-times" onClick={() => this.onHide(name)} className="p-button-text" />
            </div>
        );
    }
    renderFooter3(name) {
        return (
            <div>
                <Button label="No" icon="pi pi-times" onClick={() => this.onHide(name)} className="p-button-text" />
                <Button label="Si" icon="pi pi-check" onClick={() => {
                    this.setState({ novedadRegistrada: false });
                    this.onHide(name);
                }} autoFocus
                />
            </div>
        );
    }

    renderFooter4(name) {
        return (
            <div>
                <Button label="No" icon="pi pi-times" onClick={() => this.onHide(name)} className="p-button-text" />
                <Button label="Si" icon="pi pi-check" onClick={() => {
                    this.setState({ showNext: false });
                    this.onHide(name);
                }} autoFocus
                />
            </div>
        );
    }
    renderFooter5(name) {
        return (
            <div>
                <Button label="Cancelar" icon="pi pi-times" onClick={() => this.onHide(name)} className="p-button-text" />
                <Button label="Enviar" icon="pi pi-check" onClick={() => {
                    if (this.state.file1 !== null &&
                        this.state.file3 !== null &&
                        /*this.state.file4 !== null &&*/
                        this.state.file5 !== null 
                        /*this.state.file6 !== null*/
                    ) {

                        if (this.state.file1.type === "application/pdf" &&
                            this.state.file3.type === "application/pdf" &&
                            /*this.state.file4.type === "application/pdf" &&*/
                            this.state.file5.type === "application/pdf"
                            /*this.state.file6.type === "application/pdf"*/
                        ) {
                            let adjuntos = [];

                            let localObj1 = {
                                nombre: this.state.file1.name,
                                archivo: this.state.file1.base64
                            };
                            adjuntos.push(localObj1);

                            let localObj3 = {
                                nombre: this.state.file3.name,
                                archivo: this.state.file3.base64
                            };
                            adjuntos.push(localObj3);

                            /*let localObj4 = {
                                nombre: this.state.file4.name,
                                archivo: this.state.file4.base64
                            };
                            adjuntos.push(localObj4);*/

                            let localObj5 = {
                                nombre: this.state.file5.name,
                                archivo: this.state.file5.base64
                            };
                            adjuntos.push(localObj5);

                            /*let localObj6 = {
                                nombre: this.state.file6.name,
                                archivo: this.state.file6.base64
                            };
                            adjuntos.push(localObj6);*/


                            /*this.state.files.forEach(row => {
                                let localObj = {
                                    nombre:row.name,
                                    archivo:row.base64
                                };
                                adjuntos.push(localObj);
                            });*/

                            GeneralService.enviarArchivosLineaFabrica(this.props.id, this.props.sinat, this.state.userData.informacionCatastral.predio.claveCatastral, adjuntos)
                                .then(respuesta => {
                                    NotificationManager.success('Solicitud Enviada', "", 5000, () => { });
                                    this.setState({ finalmsj: true });

                                }, error => {
                                    NotificationManager.error('Error... Por favor intente nuevamente..', "", 5000, () => { });
                                });
                            this.setState({ sentSolicitude: true });
                            this.onHide(name);
                        }
                        else {
                            NotificationManager.error('Solo se permiten archivos pdf..', "", 5000, () => { });
                        }




                    }
                    else {
                        NotificationManager.error('Error... Todos los documentos son obligatorios..', "", 5000, () => { });
                    }
                }} autoFocus
                />
            </div>
        );
    }
    componentDidMount() {
        GeneralService.obtenerPredio(this.props.id, this.props.sinat).then(result => {
            this.setState({ userData: result.data }, () => {
                if (parseInt(result.data.status.codigo) === 0) {
                    NotificationManager.error('Error... ' + result.data.status.msg, "", 5000, () => { });
                }
                else
                    this.setState({ showInfo: true });

                this.props.stopLoader();
            }
            );
        }
            ,
            error => {
                NotificationManager.error('Error... Por favor intente nuevamente..', "", 5000, () => { });
            });
    }
    downloadPdf = (pdf) => {
        let linkSource = 'data:application/octet-stream;base64,' + pdf;
        const downloadLink = document.createElement("a");
        const fileName = "VistaPrevia.pdf";

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    vistaPrevia = () => {
        GeneralService.obtenerInformePreliminarIrm(this.props.id, this.state.sinatValue).then(resultado => {
            let pdfData = resultado.data;
            this.downloadPdf(pdfData.irm.pdf.toString());
        });

    }
    generarInforme = () => {
        GeneralService.crearInformeIrm(this.props.id, this.state.sinatValue).then(resultado => {
            let numInforme = resultado.data.informe.numeroInforme;
            GeneralService.descargarInformeIrm(numInforme).then(resultado => {
                let pdfData = resultado.data;
                this.downloadPdf(pdfData.irm.pdf.toString());
            });

        });
    }
    getTotalFormat = (rowData) => {
        return parseFloat(rowData.total).toFixed(2);
    }
    render() {
        return (
            <React.Fragment>
                <Dialog header="Advertencia" visible={this.state.displayBasic6} style={{ width: '60vw' }} footer={this.renderFooter2('displayBasic6')} onHide={() => this.onHide('displayBasic6')}>
                    Estimado(a) Usuario(a) existen valores pendientes de pago:
                    <DataTable value={this.state.debts} emptyMessage="No se encuentran registros">
                        <Column field="codigoPersona" header="Cod."></Column>
                        <Column field="apellidos" header="Apellidos y Nombres"></Column>
                        <Column field="terceraEdad" header="TE/DSC"></Column>
                        <Column field="mesAnio" header="Mes/Año"></Column>
                        <Column field="sitio" header="Sitio"></Column>
                        <Column field="valorAvaluo" header="Valor Avalúo"></Column>
                        {/*<Column field="tipo" header="Tipo"></Column>
                        <Column field="interesMora" header="Int. Mora"></Column>
                        <Column field="recargo" header="Recargo"></Column>
                        <Column field="costasJudiciales" header="Cost. Judiciales"></Column>
                        <Column field="valorAdoquinamiento" header="Adoquinamiento"></Column>
                        <Column field="valorBomberos" header="Bomberos"></Column>
                        <Column field="valorImpuestoPrincipal" header="Imp. Principal"></Column>
                        <Column field="valorServiciosAdministrativos" header="Serv. Administrativos"></Column>
                        <Column field="descuento" header="Descuento"></Column>
                        <Column field="subtotal" header="Subtotal"></Column>*/}
                        <Column header="Total" body={this.getTotalFormat}></Column>
                    </DataTable>
                    <br />
                    Estimado Usuario, usted tiene valores pendientes de pago; por favor ac&eacute;rquese a las ventanillas de Tesorer&iacute;a del municipio y cancele su deuda. <br />Recuerde obtener el "Certificado de No adeudar al municipio", el documento es requisito para la emisi&oacute;n del Informe de Regulación Municipal
                </Dialog>
                <Dialog header="Subir Archivos (Solo se permiten archivos pdf)" visible={this.state.displayBasic5} style={{ width: '50vw' }} footer={this.renderFooter5('displayBasic5')} onHide={() => this.onHide('displayBasic5')}>
                    <span>
                        Los documentos a cargar deben estar en formato .PDF, o no se podr&aacute; continuar con la solicitud.
                    </span>
                    <br />
                    <table border="0">
                        <tr>
                            <td><b>FORMULARIO DE TR&Aacute;MITES DE ADMINISTRACI&Oacute;N Y CONTROL TERRITORIO:</b></td><td><FileBase64 multiple={false} onDone={this.getFile1} /></td>
                        </tr>
                        <tr>
                            <td><b>COPIA DE LA ESCRITURA DEBIDAMENTE REGISTRADA:</b></td><td><FileBase64 multiple={false} onDone={this.getFile3} /></td>
                        </tr>
                        {/*<tr>
                            <td><b>COPIA DE CERTIFICADO DE GRAVAMEN ACTUALIZADO:</b></td><td><FileBase64 multiple={false} onDone={this.getFile4} /></td>
                    </tr>*/}
                        <tr>
                            <td><b>PAGO DEL IMPUESTO PREDIAL ACTUALIZADO:</b></td><td><FileBase64 multiple={false} onDone={this.getFile5} /></td>
                        </tr>
                        {/*<tr>
                            <td><b>CERTIFICADO DE NO ADEUDAR AL MUNICIPIO:</b></td><td><FileBase64 multiple={false} onDone={this.getFile6} /></td>
                </tr>*/}
                    </table>
                </Dialog>
                <Dialog header="Advertencia" visible={this.state.displayBasic4} style={{ width: '50vw' }} footer={this.renderFooter4('displayBasic4')} onHide={() => this.onHide('displayBasic4')}>
                    Estimado(a) Usuario(a) ¿La información fue revisada y está conforme con la misma?
                </Dialog>
                <Dialog header="Advertencia" visible={this.state.displayBasic3} style={{ width: '50vw' }} footer={this.renderFooter3('displayBasic3')} onHide={() => this.onHide('displayBasic3')}>
                    <p>Estimado(a) Usuario(a) ¿Est&aacute; seguro de continuar? El Informe de Regulación Municipal saldr&aacute; con la informaci&oacute;n vigente, es decir no se ha actualizado la informaci&oacute;n.</p>
                </Dialog>
                <Dialog header="Advertencia" visible={this.state.displayBasic} style={{ width: '50vw' }} footer={this.renderFooter2('displayBasic')} onHide={() => this.onHide('displayBasic')}>
                    {/*<p>Estimado(a) Usuario(a) por favor ac&eacute;rquese a la Direcci&oacute;n de Aval&uacute;os y Catastros del GADIP-MC para la correcci&oacute;n de la informaci&oacute;n catastral, se le indicar&aacute; que tr&aacute;mite realizar. Para mayor informaci&oacute;n visite el enlace: <a href="https://municipiocayambe.gob.ec/avaluos/" target="_blank">https://municipiocayambe.gob.ec/avaluos/</a> . </p>*/}
                    “Estimado Usuario, si contin&uacute;a el tr&aacute;mite se generar&aacute; el Informe de Regulación Municipal con la informaci&oacute;n vigente. Primero actualice su informaci&oacute;n en la oficina de la Dirección de Avalúos y Catastros, o solicite virtualmente a través de la plataforma de tele tr&aacute;mites”. Para m&aacute;s informaci&oacute;n consulte el siguiente enlace: <a href="https://municipiocayambe.gob.ec/avaluos/" target="_blank">https://municipiocayambe.gob.ec/avaluos/</a>.
                </Dialog>
                <Dialog header="Información" visible={this.state.finalmsj} style={{ width: '50vw' }} footer={this.renderFooter2('finalmsj')} onHide={() => this.onHide('finalmsj')}>
                    Estimado Usuario:<br />
                    Su solicitud fue realizada correctamente, su tr&aacute;mite se encuentra en revisión de la Jefatura de Administración y Control del Territorio. En caso de requerirse una inspección su informe será emitido hasta dentro de 10 días, en caso contrario su informe será emitido aproximadamente en 72 horas. Presione el bot&oacute;n "Aceptar" y posteriormente el bot&oacute;n "INICIO" para salir de la pantalla.<br />                  
                    <b>Verifique el estado de su trámite:</b><br />
                    1. Presione el bot&oacute;n INICIO y seleccione la opción "Emisi&oacute;n de Informe de Regulación Municipal".<br />
                    2. Ingrese el c&oacute;digo de validaci&oacute;n que aparece en pantalla y presione "Enviar".<br />
                    3. Consulte su predio mediante clave catastral o c&eacute;dula de identidad.<br />
                    4. Presione el bot&oacute;n "Mostrar Informes" y revise la informaci&oacute;n del estado del tr&aacute;mite, si se indica que requiere inspecci&oacute;n, realice el pago correspondiente en las ventanillas <br />
                    de Tesorer&iacute;a del municipio por el concepto de "Derecho de Inspecci&oacute;n" y cargue el comprobante de pago a través del bot&oacute;n "Subir Archivo".<br />
                    5. Para descargar el informe generado repita los pasos del 1 al 4 y descargue el informe presente en la sección inferior.

                </Dialog>

                <Dialog header="Registrar Novedades" visible={this.state.displayResponsive} onHide={() => this.onHide('displayResponsive')} breakpoints={{ '960px': '75vw' }} style={{ width: '50vw' }} footer={this.renderFooter('displayResponsive')}>
                    <p>Por favor ingrese su novedad:<br />
                        <InputTextarea className="boxsizingBorder" style={{ minWidth: "100%", maxWidth: "100%" }} autoResize={true} rows={5} cols={80} value={this.state.novedades} onChange={(e) => { this.setState({ novedades: e.target.value }); }} />
                    </p>
                </Dialog>
                <Dialog header="Información" visible={this.state.novedadesmsj} onHide={() => this.onHide('novedadesmsj')} breakpoints={{ '960px': '75vw' }} style={{ width: '50vw' }} footer={this.renderNovedadesFooter('novedadesmsj')}>
                    <p>
                        “Estimado Usuario, si contin&uacute;a el tr&aacute;mite se generar&aacute; el Informe de Regulación Municipal con la informaci&oacute;n vigente.<br />
                        Verifique su información en la oficina de la JEFATURA DE ADMINISTRACI&Oacute;N Y CONTROL DE TERRITORIO.
                        <br />
                        Requisitos para la atenci&oacute;n presencial:<br />
                        Formulario de tr&aacute;mites de administraci&oacute;n y control de territorio.<br />
                        Copia de la escritura debidamente registrada.<br />
                        Copia de certificado de gravamen actualizado.<br />
                        Pago del impuesto predial actualizado.<br />
                        Certificado de no adeudar al municipio.<br />
                    </p>
                </Dialog>
                {this.state.showInfo &&
                    <div>
                        <div style={{ margin: "20px" }}>
                            <span>Revisión de la Informaci&oacute;n Catastral.</span><br />
                            <span>Estimado(a) Usuario(a), por favor revise la informaci&oacute;n catastral que aparece en la pantalla, si la información no corresponde presione "Registrar Novedades"; si no existen novedades presione "Continuar"</span>
                        </div>
                        <Panel header="REVISIÓN DE INFORMACIÓN CATASTRAL" style={{ maxWidth: "100%" }}>

                        </Panel>
                        <Grid container spacing={0}>
                            <Grid item xs={12} sm={6}>
                                <Panel header="INFORMACIÓN PREDIAL EN UNIPROPIEDAD" style={{ textAlign: "center", maxWidth: "100%", display: "block", marginLeft: "auto", marginRight: "auto" }}>
                                    <table border="1" width="100%" style={{ textAlign: "left" }}>
                                        <tr>
                                            <td colSpan={2}><b>DATOS DEL TITULAR DE DOMINIO</b></td>
                                        </tr>
                                        <tr>
                                            <td width="50%">C.C/R.U.C</td>
                                            <td>{this.state.userData.informacionCatastral.titularDominio.cedulaRuc}</td>
                                        </tr>
                                        <tr>
                                            <td>Nombre o Raz&oacute;n Social</td>
                                            <td>{this.state.userData.informacionCatastral.titularDominio.nombreRazonSocial}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}><b>DATOS DEL PREDIO</b></td>
                                        </tr>
                                        <tr>
                                            <td>Clave Catastral</td>
                                            <td>{this.state.userData.informacionCatastral.predio.claveCatastral}</td>
                                        </tr>
                                        <tr>
                                            <td>Clave Catastral Anterior</td>
                                            <td>{this.state.userData.informacionCatastral.predio.claveCatastralAnterior}</td>
                                        </tr>
                                        <tr>
                                            <td>En derechos y acciones</td>
                                            <td>{(this.state.userData.informacionCatastral.predio.derechosAcciones) ? "SI" : "NO"}</td>
                                        </tr>
                                        <tr>
                                            <td>&Aacute;rea de construcci&oacute;n</td>
                                            <td>{this.state.userData.informacionCatastral.predio.areaConstruccion} m2</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}><b>DATOS DEL LOTE</b></td>
                                        </tr>
                                        <tr>
                                            <td>&Aacute;rea seg&uacute;n escritura:</td>
                                            <td>{this.state.userData.informacionCatastral.lote.areaEscritura} m2</td>
                                        </tr>
                                        <tr>
                                            <td>&Aacute;rea gr&aacute;fica</td>
                                            <td>{this.state.userData.informacionCatastral.lote.areaGrafica} m2</td>
                                        </tr>
                                        <tr>
                                            <td>Frente Principal:</td>
                                            <td>{this.state.userData.informacionCatastral.lote.frenteTotal}</td>
                                        </tr>
                                        <tr>
                                            <td>Tiene Construcci&oacute;n:</td>
                                            <td>{this.state.userData.informacionCatastral.lote.construccion ? "SI" : "NO"}</td>
                                        </tr>
                                        <tr>
                                            <td>Parroquia:</td>
                                            <td>{this.state.userData.informacionCatastral.lote.divisionPolitica.parroquia?this.state.userData.informacionCatastral.lote.divisionPolitica.parroquia.nombre:"-"}</td>
                                        </tr>
                                        <tr>
                                            <td>Barrio/Sector:</td>
                                            <td>{
                                                typeof this.state.userData.informacionCatastral.lote.divisionPolitica.barrio !== "undefined" ?
                                                    this.state.userData.informacionCatastral.lote.divisionPolitica.barrio.nombre : ""
                                            }</td>
                                        </tr>
                                    </table>
                                    <br />
                                    <table border="0" width="100%">
                                        <tr>
                                            <td colspan="2">
                                                {/*<button onClick={this.vistaPrevia}>Vista Previa</button> 
                                        <button onClick={this.generarInforme}>Generar Informe</button>
                                        */}
                                                {this.state.showNext &&
                                                    <React.Fragment>
                                                        {(!this.state.sentSolicitude) &&
                                                            <div>
                                                                <Button label="Continuar" onClick={() => {
                                                                    /*
                                                                    if(this.state.novedadRegistrada)
                                                                    this.onClickCustomize('displayBasic3',()=>{
                                                                    });
                                                                    else {
                                                                        this.onClickCustomize('displayBasic4',()=>{
                                                                        });
                                                                        this.setState({showNext:false});
                                                                    } */
                                                                    this.onClickCustomize('displayBasic4', () => {
                                                                    });


                                                                }} />
                                                                &nbsp;&nbsp;
                                                                <Button label="Registrar Novedades" icon="pi pi-external-link" onClick={() => {
                                                                    this.setState({ novedades: "" }, () => {
                                                                        this.onClick('displayResponsive');
                                                                    });
                                                                }} />
                                                            </div>}
                                                    </React.Fragment>
                                                }
                                                {(!this.state.showNext && !this.state.sentSolicitude) &&
                                                    <div>
                                                        <Button label="Registrar Novedades" icon="pi pi-external-link" onClick={() => {
                                                            this.setState({ novedades: "" }, () => {
                                                                this.onClick('displayResponsive');
                                                            });
                                                        }} /> &nbsp;&nbsp;
                                                        <Tooltip title="Presione el botón y descargará un documento de consulta. El documento sirve solamente para fines informativos.">
                                                            <Button label="Documento Informativo" icon="pi pi-external-link" onClick={this.vistaPrevia} />
                                                        </Tooltip>
                                                        &nbsp; &nbsp;
                                                        <Tooltip title="Presione el botón para continuar con la solicitud del Informe de Regulación Municipal (IRM)">
                                                            <Button label="Solicitar Informe" icon="pi pi-external-link" onClick={
                                                                () => {
                                                                    //this.state.userData.informacionCatastral.predio.claveCatastra
                                                                    GeneralService.getDebtsQuery(this.state.userData.informacionCatastral.titularDominio.cedulaRuc).then(rsp => {
                                                                        let rows = rsp.data.row;
                                                                        if (rows) {
                                                                            if (rows.length > 0) {
                                                                                let toShow = [];
                                                                                rows.forEach(indRow => {
                                                                                    let localObj = {
                                                                                        codigoPersona: indRow[11],
                                                                                        apellidos: indRow[10],
                                                                                        terceraEdad: indRow[25],
                                                                                        mesAnio: indRow[1],
                                                                                        tipo: indRow[0],
                                                                                        interesMora: indRow[9],
                                                                                        recargo: indRow[8],
                                                                                        descuento: indRow[7],
                                                                                        valorAvaluo: indRow[12],
                                                                                        costasJudiciales: indRow[6],
                                                                                        valorAdoquinamiento: indRow[24],
                                                                                        valorBomberos: indRow[21],
                                                                                        valorImpuestoPrincipal: indRow[20],
                                                                                        valorServiciosAdministrativos: indRow[22],
                                                                                        total: indRow[4],
                                                                                        sitio: indRow[3],
                                                                                        subtotal: indRow[5],
                                                                                    };
                                                                                    toShow.push(localObj);
                                                                                });
                                                                                this.setState({ debts: toShow }, () => {
                                                                                    this.onClick('displayBasic5');
                                                                                });
                                                                            }                                                                           
                                                                                this.onClick('displayBasic5');                                                                          
                                                                        }

                                                                    }, error => {
                                                                        NotificationManager.error('Error... Por favor intente nuevamente..', "", 5000, () => { });
                                                                    });

                                                                }
                                                            } />
                                                        </Tooltip>
                                                    </div>
                                                }
                                            </td>
                                        </tr>
                                    </table>
                                </Panel>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Panel header={"IMPLANTACIÓN GRÁFICA DEL LOTE"} style={{ maxWidth: "100%", display: "block", marginLeft: "auto !important", marginRight: "auto !important" }}>

                                    <img src={"data:image/png;base64," + this.state.userData.informacionCatastral.ubicacionLote.grafico} width="50%" style={{ marginLeft: "auto", marginRight: "auto", display: "block" }} />

                                </Panel>
                            </Grid>
                        </Grid>
                        {!this.state.showNext &&
                            <div>
                                {(typeof this.state.userData.vias !== "undefined") &&
                                    <Panel header="VIAS" style={{ maxWidth: "100%" }}>
                                        <table border="0" width="100%">
                                            <tr>
                                                <td>
                                                    <b>Tipo</b>
                                                </td>
                                                <td>
                                                    <b>Nombre:</b>
                                                </td>
                                                <td>
                                                    <b>Ancho (m):</b>
                                                </td>
                                                <td>
                                                    <b>Acera (m):</b>
                                                </td>
                                                <td>
                                                    <b>Calzada:</b>
                                                </td>
                                            </tr>
                                            {this.state.userData.vias.map(rowAfectacion =>
                                                <tr>
                                                    <td>
                                                        {rowAfectacion.tipo}
                                                    </td>
                                                    <td>
                                                        {rowAfectacion.nombre}
                                                    </td>
                                                    <td>
                                                        {rowAfectacion.ancho}
                                                    </td>
                                                    <td>
                                                        {rowAfectacion.acera}
                                                    </td>
                                                    <td>
                                                        {rowAfectacion.ancho - 2 * rowAfectacion.acera}
                                                    </td>
                                                </tr>
                                            )
                                            }
                                        </table>
                                    </Panel>
                                }
                                <Panel header="REGULACIONES" style={{ maxWidth: "100%" }}>
                                    <b>ZONIFICACI&Oacute;N</b>
                                    <Grid container spacing={0}>
                                        <Grid item xs={8}>
                                            {this.state.userData.regulaciones.map(regulacion =>
                                                <div>
                                                    <b>ZONA</b>
                                                    <br />
                                                    Zona: {regulacion.zona}
                                                    <br />
                                                    Lote m&iacute;nimo: {regulacion.loteMinimo}
                                                    <br />
                                                    Frente m&iacute;nimo: {regulacion.frenteMinimo}
                                                    <br />
                                                    COS PB: {regulacion.cosPb} %
                                                    <br />
                                                    COS TOTAL: {regulacion.cosTotal}%
                                                    <br />
                                                    Forma de ocupaci&oacute;n de suelo: {regulacion.formaOcupacion.nombre}
                                                    <br />
                                                    <b>CLASIFICACI&Oacute;N DE SUELO:</b> ({regulacion.clasificacionSuelo.codigo}) {regulacion.clasificacionSuelo.nombre}
                                                    <br />
                                                    <b>USO DE SUELO DE SUELO:</b> ({regulacion.usoSuelo.codigo}) {regulacion.usoSuelo.nombre}
                                                </div>
                                            )}
                                        </Grid>
                                        <Grid item xs={2}>
                                            <b>PISOS</b>
                                            {this.state.userData.regulaciones.map(regulacion =>
                                                <div>
                                                    <br />
                                                    Altura: {regulacion.alturaPisos}m
                                                    <br />
                                                    N&uacute;mero de pisos: {regulacion.numeroPisos}
                                                </div>
                                            )}
                                        </Grid>
                                        <Grid item xs={2}>
                                            <b>RETIROS</b>
                                            {this.state.userData.regulaciones.map(regulacion =>
                                                <div>
                                                    <br />
                                                    Frontal: {regulacion.retiroFrontal} m
                                                    <br />
                                                    Lateral 1: {regulacion.retiroLateral1} m
                                                    <br />
                                                    Lateral 2: {regulacion.retiroLateral2} m
                                                    <br />
                                                    Posterior: {regulacion.retiroPosterior} m
                                                    <br />
                                                    Entre bloques: {regulacion.distanciaEntreBloques} m
                                                </div>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Panel>
                                <Panel header="COBERTURA DE SERVICIOS BÁSICOS" style={{ maxWidth: "100%" }}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={6}>
                                            <b>ABASTECIMIENTO DE AGUA:</b> {this.state.userData.servicios.agua}
                                            <br />
                                            <b>ENERG&Iacute;A EL&Eacute;CTRICA:</b> {this.state.userData.servicios.electricidad}
                                        </Grid>
                                        <Grid item xs={6}>
                                            <b>ALCANTARILLADO:</b> {this.state.userData.servicios.alcantarillado}
                                            <br />
                                            <b>OTROS:</b> {this.state.userData.servicios.otros}
                                        </Grid>
                                    </Grid>
                                </Panel>
                                {(typeof this.state.userData.afectacionesVias !== "undefined") &&
                                    <Panel header="AFECTACIÓN VIAL" style={{ maxWidth: "100%" }}>
                                        <table border="0" width="100%">
                                            <tr>
                                                <td>
                                                    <b>Tipo</b>
                                                </td>
                                                <td>
                                                    <b>Nombre:</b>
                                                </td>
                                                <td>
                                                    <b>Ancho Total:</b>
                                                </td>
                                                <td>
                                                    <b>Ret (m):</b>
                                                </td>
                                                <td>
                                                    <b>Observaci&oacute;n:</b>
                                                </td>
                                            </tr>
                                            {this.state.userData.afectacionesVias.map(rowAfectacion =>
                                                <tr>
                                                    <td>
                                                        {rowAfectacion.tipo}
                                                    </td>
                                                    <td>
                                                        {rowAfectacion.nombre}
                                                    </td>
                                                    <td>
                                                        {rowAfectacion.ancho}
                                                    </td>
                                                    <td>
                                                        {rowAfectacion.retiro}
                                                    </td>
                                                    <td>
                                                        {rowAfectacion.observacion}
                                                    </td>
                                                </tr>
                                            )
                                            }
                                        </table>
                                    </Panel>
                                }
                                {(typeof this.state.userData.protecciones !== "undefined") &&
                                    <Panel header="PROTECCIONES" style={{ maxWidth: "100%" }}>
                                        <table border="0" width="100%">
                                            <tr>
                                                <td>
                                                    <b>Tipo</b>
                                                </td>
                                                <td>
                                                    <b>Nombre:</b>
                                                </td>
                                                <td>
                                                    <b>Observaci&oacute;n:</b>
                                                </td>
                                            </tr>
                                            {this.state.userData.protecciones.map(rowAfectacion =>
                                                <tr>
                                                    <td>
                                                        {rowAfectacion.tipo}
                                                    </td>
                                                    <td>
                                                        {rowAfectacion.nombre}
                                                    </td>
                                                    <td>
                                                        {rowAfectacion.observacion}
                                                    </td>
                                                </tr>
                                            )
                                            }
                                        </table>
                                    </Panel>
                                }
                                {(typeof this.state.userData.notasAdicionales !== "undefined") &&
                                    <Panel header="LISTA DE NOTAS" style={{ maxWidth: "100%" }}>
                                        <table border="1" width="100%">
                                            {this.state.userData.notasAdicionales.map(nota =>
                                                <tr>
                                                    <td>
                                                        {nota}
                                                    </td>
                                                </tr>
                                            )
                                            }
                                        </table>
                                    </Panel>
                                }
                            </div>
                        }
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>
                }
                {this.state.showError &&
                    <div style={{ color: "red" }}>
                        <br />
                        {this.state.error}
                    </div>}
            </React.Fragment>
        );
    }
}
export default Predio;