import axios from 'axios';
class MapService{
    /*************************BATCH PIT **************************************/
    constructor(){
        this.api_url="";
        this.geoserver_url="";
        fetch(`${process.env.PUBLIC_URL}/config.json`).then((r) => r.json())
        .then((initOptions) =>{
          this.api_url=initOptions.api_url;
          this.geoserver_url=initOptions.geoserver_url;
        });
      }
    obtenerListaFuente=()=>{
        return axios.get(`${this.api_url}/catastro/rest/v1.0/consultas/listaFuente`);
    }
    
    /*********************FIN BATCH PIT **************************************/

}
export default new MapService();

