import React, { Component } from 'react';
import { BrowserRouter, Route, Switch} from 'react-router-dom';
import ModuleTemplate from './modulos/ModuleTemplate';
import Map from './Charts/Map';
import Home from './Home';
import ConsultaDeuda from './ConsultaDeuda/ConsultaDeuda';
import LineaFabrica from './LineaFabrica/LineaFabrica';
import Icus from './Icus/Icus';
import CompatibilidadSuelo from './CompatibilidadSuelo/CompatibilidadSuelo';
 
class Router extends Component {
    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <ModuleTemplate>
                        <Route component={({ match }) =>
                            <div>
                                <Route path='/' exact component={Home} />
                               {/* <Route path='/mapa' exact component={Map} />
                                <Route path='/consulta_deuda' exact component={ConsultaDeuda} />*/}
                                <Route path='/linea_fabrica' exact component={LineaFabrica} />
                                <Route path='/icus' exact component={Icus} />
                                <Route path='/icus/predio/:predio/:id' exact component={CompatibilidadSuelo} />
                            </div>
                        } />
                    </ModuleTemplate>
                </Switch>
            </BrowserRouter>
        );
    }

}
export default Router;
