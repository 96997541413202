import React,{Component} from 'react';
import MainTemplate from '../MainTemplate';
import {connect} from 'react-redux';
import updateModule from '../../redux/actions/updateModule';

class ModuleTemplate extends Component
{
    constructor(props){
        super(props);
        this.state={
            module_id: this.props.module.module, //this.props.match.params.module_name
            breadcrumb: this.props.breadcrumb.breadcrumb
        }
    }
    getModuleName=()=>{
        return this.state.module_id.toUpperCase();
    }
    render()
    {
        return(
            <React.Fragment>
                <MainTemplate module_name={this.getModuleName()} breadcrumb={this.state.breadcrumb} module_id={this.state.module_id} holder={this.props.children}/>
            </React.Fragment> 
        );
    }
}

const mapStateToProps=(state)=>{
    return {
       module: state.module,
       breadcrumb: state.breadcrumb

    };
 }
 
 
 const mapDispatchToProps = {
    updateModule
 }
 
 export default connect(mapStateToProps,mapDispatchToProps)(ModuleTemplate);