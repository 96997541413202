import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import logo from "../assets/images/logo.png";
import SearchIcon from '@material-ui/icons/Search';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import PublicIcon from '@material-ui/icons/Public';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import { useHistory } from "react-router";
import { connect } from "react-redux";
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Cayambe
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

function MainTemplate(props) {
  let history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [open2, setOpen2] = React.useState(true);
  const [tramites, setTramites] = React.useState(true);

  const handleClick = () => {
    setOpen2(!open2);
  };
  const handleTramitesClick = () => {
    setTramites(!tramites);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  useEffect(() => {
  });
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    <div className={classes.root}>


      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)} style={{ background: "#3c8dbc" }}
        elevation={0}
      >

        <Toolbar>

          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            {/*INICIO*/}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>

          <img src={logo} alt="logo" style={{ maxWidth: "160px" }} />
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <div>
            <ListItem button onClick={() => { history.push("/"); }}>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="INICIO" />
            </ListItem>
           {/* <ListItem button onClick={handleClick}>
              <ListItemIcon>
                <SearchIcon />
              </ListItemIcon>
              <ListItemText primary="CONSULTAS" />
              {open2 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open2} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                
                <ListItem button className={classes.nested} onClick={()=>{history.push("/consulta_deuda");}}>
                  <ListItemIcon>
                    <AttachMoneyIcon />
                  </ListItemIcon>
                  <ListItemText primary="Deudas" />
                </ListItem>
                
                <ListItem button className={classes.nested} onClick={() => { history.push("/mapa"); }}>
                  <ListItemIcon>
                    <PublicIcon />
                  </ListItemIcon>
                  <ListItemText primary="Geoportal" />
                </ListItem>
              </List>
            </Collapse>*/}

            <ListItem button onClick={handleTramitesClick}>
              <ListItemIcon>
                <ContactMailIcon />
              </ListItemIcon>
              <ListItemText primary="TRAMITES" />
              {tramites ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={tramites} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button className={classes.nested} onClick={() => { history.push("/linea_fabrica"); }}>
                  <ListItemIcon>
                    <HomeWorkIcon />
                  </ListItemIcon>
                  <ListItemText primary="IRM" />
                </ListItem>
                <ListItem button className={classes.nested} onClick={() => {
                   window.open("https://impuestos.gadipmc.gob.ec/Manual_IRM.pdf");
                 }}>
                  <ListItemIcon>
                    <PictureAsPdfIcon />
                  </ListItemIcon>
                  <ListItemText primary="IRM Manuales" />
                </ListItem>
                <ListItem button className={classes.nested} onClick={() => { 
                   window.open("https://youtu.be/pS2aTOF-uf8");
                }}>
                  <ListItemIcon>
                    <VideoLibraryIcon />
                  </ListItemIcon>
                  <ListItemText primary="IRM Video" />
                </ListItem>
                <ListItem button onClick={() => { history.push("/icus"); }}>
                  <ListItemIcon>
                    <LocationSearchingIcon />
                  </ListItemIcon>
                  <ListItemText primary="ICUS" />
                </ListItem>
                <ListItem button className={classes.nested} onClick={() => { 
                   window.open("https://impuestos.gadipmc.gob.ec/Manual_ICUS.pdf");
                }}>
                  <ListItemIcon>
                    <PictureAsPdfIcon />
                  </ListItemIcon>
                  <ListItemText primary="ICUS Manuales" />
                </ListItem>
                <ListItem button className={classes.nested} onClick={() => { 
                   window.open("https://youtu.be/5Pz_p6rAxKY");
                }}>
                  <ListItemIcon>
                    <VideoLibraryIcon />
                  </ListItemIcon>
                  <ListItemText primary="ICUS Video" />
                </ListItem>
                {/*
                <ListItem button className={classes.nested} onClick={()=>{history.push("/compatibilidad_suelo");}}>
                  <ListItemIcon>
                    <HowToRegIcon />
                  </ListItemIcon>
                  <ListItemText primary="Uso de suelo" />
                </ListItem>
                */}
              </List>
            </Collapse>
          </div>
        </List>
        <Divider />
      </Drawer>
      <div className={classes.content}>
        <div className={classes.appBarSpacer} />
        {props.holder}


      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  breadcrumb2: state.breadcrumb.breadcrumb
});
export default connect(mapStateToProps)(MainTemplate);